import * as React from "react";
import Chart from "react-apexcharts";
import { getDashboard } from "../services";
import { notify } from "../components/actions/CommonActions";
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiCheckCircleFill,
  PiWarningFill,
} from "react-icons/pi";
import { FaLock, FaLockOpen } from "react-icons/fa";

const Dashboard = ({ history }) => {
  const [loading, setLoading] = React.useState(false);
  const [dataGotten, setDataGotten] = React.useState(null);
  const [ticketCategories, setTicketCategories] = React.useState(null);

  const numberFormat = (value, toFixed = 2) => {
    const num = Number(value);
    if (Number.isInteger(num)) {
      return num.toLocaleString(); // Handles integer values without decimals
    } else {
      return num
        .toFixed(toFixed) // Ensures the specified number of decimal places
        .replace(/\d(?=(\d{3})+\.)/g, "$&,"); // Adds commas for thousands
    }
  };

  React.useEffect(() => {
    const initiate = async () => {
      setLoading(true);
      await getDashboard()
        .then((res) => {
          // console.log('res ', res);
          setDataGotten(res?.data);
        })
        .catch((error) => {
          notify("danger", "OOPS", error?.response?.data?.message);
          console.log('error ', error);
        });
      setLoading(false);
    };
    initiate();
  }, []);

  React.useEffect(() => {
    if (dataGotten) {
      const labels = ["NAICOM", "NIID"];
      const naicom = dataGotten.uploadedToNaicom ?? 0;
      const niid = dataGotten.uploadedToNiid ?? 0;
      const series = [naicom, niid];

      const options = {
        series,
        options: {
          chart: {
            type: "donut",
          },
          stroke: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            horizontalAlign: "center",
            position: "bottom",
            floating: false,
            formatter: (val, opt) => {
              return `${val} - ${opt?.w?.globals?.series[opt?.seriesIndex]}`;
            },
            markers: {
              width: 8,
              height: 8,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "75%",
                labels: {
                  show: true,
                  value: {
                    fontFamily: "Poppins,sans-serif",
                    fontWeight: 700,
                    offsetY: 0,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    label: "TOTAL",
                    color: "#000",
                    fontSize: "10px",
                    fontFamily: "Poppins,sans-serif",
                    fontWeight: 600,
                    formatter: function (w) {
                      const val = w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                      return val;
                    },
                  },
                },
              },
            },
          },
          labels,
          colors: ["#B0D7EF", "#002C47"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };

      setTicketCategories(options);
    }
  }, [dataGotten]);

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, minHeight: '100vh' }}>
          <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: "98px" }}
      >
        <div
          className=" h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row"
          style={{ border: "1px solid #d4d4d8", width: '30%' }}
        >
          <div>
            <p className="mb-3 text-muted">Policy count</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(dataGotten?.policyCount)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <PiCheckCircleFill
              style={{ fontSize: "2.1rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8", width: '30%' }}
        >
          <div>
            <p className="mb-3 text-muted">Uploaded to NAICOM</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(dataGotten?.uploadedToNaicom)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <PiCheckCircleFill
              style={{ fontSize: "2.1rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className=" h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8", width: '30%' }}
        >
          <div>
            <p className="mb-3 text-muted">Uploaded to NIID</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(dataGotten?.uploadedToNiid)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <PiCheckCircleFill
              style={{ fontSize: "2.1rem" }}
              className="text-primary"
            />
          </div>
        </div>
      </div>

          <div className="m-0 mr-0 mt-5 d-flex align-items-center justify-content-center">
            <div className="row w-100 mr-0" style={{ height: "400px" }}>
              <div className="col-3 pr-0 w-100">
                <div
                  className="w-100 h-100 rounded p-2 pt-4"
                  style={{
                    border: "1px solid #d4d4d8",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <p className="m-0 text-muted">Uploaded files</p>
                  <div className="donut h-100 d-flex align-items-center justify-content-center">
                    {ticketCategories && (
                      <Chart
                        options={ticketCategories.options}
                        series={ticketCategories.series}
                        type="donut"
                        height={280}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
