import { Button, Modal } from "react-bootstrap";
import React from "react";

export const ActivateUserModal = ({
  show,
  toggle,
  handleActivateUser,
  isChanging,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Activate User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to proceed? Proceeding will activate this user.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleActivateUser();
          }}
          disabled={isChanging}
        >
          {isChanging ? "Activating..." : "Activate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
