import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { registerNewUser } from "../../services";
import { notify } from "../actions/CommonActions";
import { MdCancel } from "react-icons/md";
import { createRoles } from "../../services";
// import { AuthContext } from "../../helpers/AuthContext";

export const NewRoleModal = ({
  showUser,
  setShowUser,
  toggleShowUser,
  refetch,
  roles
}) => {
  const [register, setRegister] = useState({
    name: "",
  });
  const [permissions, setPermissions] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);
  //   const { roles, user } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const handleRegisterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setRegister({ ...register, [name]: value });
  };

  const handleRoleChange = (e) => {
    const target = e.target.value;
    if(!permissions.includes(target)){
    setPermissions((prevPermissions) => [...prevPermissions, target]);
    }
  };

  const removePermission = (i) =>{
    const newPermissions = [...permissions];
    newPermissions.splice(i, 1);
    setPermissions(newPermissions)
  }

  const registerUser = (values) => {
    setIsRegistering(true);
    // e.preventDefault();
    createRoles(values)
      .then((res) => {
        notify("success", "Role created.", res?.message);
        // setShowUser(false);
        setRegister({
            name: "",
          })
        setPermissions([])
        setIsRegistering(false);
        toggleShowUser()
        refetch();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsRegistering(false);
      });
  };

  return (
    <Modal
      show={showUser}
      onHide={toggleShowUser}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>New Role</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form className="d-flex flex-column gap-3">
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" onChange={handleRegisterChange} />
              </Form.Group>
            </Row>
            <Form.Group>
              <Form.Label>Permissions</Form.Label>
              <Form.Select name="permissions" onChange={handleRoleChange}>
                <option></option>
                {roles?.data?.map((role, index) => {
                  return (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  );
                })}
              </Form.Select>
              {/* <Form.Control name="roleId" onChange={handleRegisterChange} /> */}
            </Form.Group>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {permissions?.map((item, index)=>
              <p
              key={index}
                style={{
                  background: "#0075C9",
                  padding: "5px 10px",
                  color: "white",
                  borderRadius: "20px",
                }}
              >
                {item} <MdCancel onClick={()=> removePermission(index)} />
              </p>
              )}
              </div>

            <Button
              onClick={(e) => {
                  e.preventDefault();
                const sentData = {
                    name: register?.name,
                    permissions: permissions
                }
                registerUser(sentData);
              }}
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isRegistering}
            >
              {isRegistering ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
        >
          Search
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
