import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { loginUser } from "../services";
import { notify } from "../components/actions/CommonActions";
import { useNavigate } from "react-router-dom";
import LoginImage from '../assets/images/blueLogo2.png';
import logo from "../assets/images/blueLogo.png";
import axios from "axios";

const Login = () => {
  const [login, setLogin] = useState({ email: "", password: "" });
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  async function loginAcc() {
    setIsLoggingIn(true);
    try {
      const data = await loginUser(login)
        // console.log("works");
        notify("success", "Login Successful", "Login successful");
        sessionStorage.setItem("token", data?.data?.token);
        sessionStorage.setItem("refreshToken", data?.data?.refreshToken);
        sessionStorage.setItem("userdata", JSON.stringify(data?.data));
        navigate("/dashboard")
  
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
      </Col>
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Login</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Enter your credentials
          </p>
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" onChange={handleLoginChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleLoginChange}
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
              onClick={(e)=>{
                e.preventDefault()
                loginAcc()
              }}
            >
              {isLoggingin ? "Logging in..." : "Login"}
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
