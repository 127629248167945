import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownDivider,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import "../App.css";
import {
  Link,
  NavLink,
  Outlet,
  redirect,
  useLocation,
  useNavigation,
  useNavigate,
} from "react-router-dom";
import { CiHome, CiMoneyBill, CiSearch } from "react-icons/ci";
import { GoPlus, GoPackage } from "react-icons/go";
import { FiCalendar, FiCreditCard, FiLogOut, FiSettings } from "react-icons/fi";
import { GiChart } from "react-icons/gi";
import logo from "../assets/images/logo.png";
import "react-notifications-component/dist/theme.css";
import { AuthContext } from "../components/helper/AuthContext";
import { FaFile, FaUserPlus } from "react-icons/fa";
import { IoDocumentAttach } from "react-icons/io5";
// import { FoundMandates } from "../components/modals/FoundMandates";


export const Root = () => {
  const [mandateCode, setMandateCode] = useState("");
  const [mandates, setMandates] = useState();
  const [userData, setUserData] = useState(null)
  const [show, setShow] = useState(false);
  const [user, setUser] = useState('')
  const roles= ''
  const navigate = useNavigate();


  const toggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const userInfo = sessionStorage.getItem("userdata");
    setUserData(JSON.parse(userInfo));
    const isAuth = sessionStorage.getItem("token");
    !isAuth && navigate("/");
  }, []);

  const token = sessionStorage.getItem("token");

  const retrieveMandate = async () => {
    try {
      const constructedMandate = {
        auth: {
        },
        mandateCodes: [mandateCode],
      };
      const mandate = await fetch(
        `${process.env.REACT_APP_BASE_URL}/nibss/status?token=${token}`,
        {
          method: "POST",
          // mode: "no-cors",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            // Token: `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiIxOTgzYTBhZi05OTJlLTRmNzAtOTc5Ni0xYTJjNTJkMGUwOWQiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMjc5YzdiMWItYmEwNi00MjdiLWE2ODEtYzhhNTQ5MmQyOTNkL3YyLjAiLCJpYXQiOjE3MDIwMjIxNzAsIm5iZiI6MTcwMjAyMjE3MCwiZXhwIjoxNzAyMDI2MDcwLCJhaW8iOiJBU1FBMi84VkFBQUF1WUFRRlBuNGNteGpPOEtyZ0dTdExjYjhVQS9zaytGSGtZOU9rSkpld3I4PSIsImF6cCI6IjE5ODNhMGFmLTk5MmUtNGY3MC05Nzk2LTFhMmM1MmQwZTA5ZCIsImF6cGFjciI6IjEiLCJyaCI6IjAuQVlJQUczdWNKd2E2ZTBLbWdjaWxTUzBwUGEtZ2d4a3VtWEJQbDVZYUxGTFE0SjJDQUFBLiIsInRpZCI6IjI3OWM3YjFiLWJhMDYtNDI3Yi1hNjgxLWM4YTU0OTJkMjkzZCIsInV0aSI6InIySHFmX2hJR0V1cXptZzlMMFE0QUEiLCJ2ZXIiOiIyLjAifQ.A83_v_TeCgYcuwTJHRS-RZSqmsN96UruMQCDeL9WA_vybQ_gZBLN0ciL1xQumjqKy1MVrWTRoekO11zWru852xCCvA3IOs7TjxJlzo2I7o2t01eJADxP6NYuNcdg2iZa_YLqPFbbozby_PDGedXtWoQt38V5DBRbJjqFUg6NwoFvmu_bj7FBNCUZoXTTWCejz2d6Ez29_LVuy5YcyjEhYNAKmEhFZElTFtAvVfai9V1od_ZDv9nnTxhKPio3N_sGQd8oeMiXv0elYC3va-K4nPqpdkXt4vFu3D9Rt4sVByJa8Gm8cZ8iBOt8d97T2l7A3RWwfCSyhhcEMzo-Uphpfg`,
          },
          body: JSON.stringify(constructedMandate),
        }
      ).then((res) => res.json());

      if (mandate?.response?.message === "Unauthorized") {
        sessionStorage.removeItem("token");
        navigate("/");
        return;
      } else {
        setMandates(mandate);
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();

  const roleName = roles?.data?.find(
    (role) => role?.id === user?.userData?.role
  );

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col
          xs={2}
          style={{ backgroundColor: "#007dcc", color: "#fff", paddingRight: 0 }}
        >
          <div className="d-flex align-items-center center flex-column p-2">
            <div className="align-self-start">
              <img src={logo} alt="logo" className="logo" />
              {/* <p style={{ fontSize: "0.7rem" }}>Live with confidence</p> */}
            </div>
            {/* <InputGroup style={{ marginTop: "20px" }}>
              <Form.Control
                style={{
                  color: "#fff",
                  fontSize: "0.7rem",
                  // borderRadius: "50px",
                  // width: "100%",
                  // border: "1px solid #fff",
                  backgroundColor: "transparent",
                  // marginTop: "20px",
                }}
                placeholder="Search Mandate"
                className="search-mandate"
                onChange={(e) => setMandateCode(e.target.value)}
              />
              <Button
                style={{
                  border: "1px solid #fff",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  color: "#000",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  retrieveMandate();
                }}
              >
                <CiSearch />
              </Button>
            </InputGroup> */}
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            {/* <NavLink
              to={"/dashboard"}
              style={{ color: "#fff" }}
              className="p-2"
            >
              <CiHome /> Home
            </NavLink> */}

            {user?.userData?.role === 2 && (
              <NavLink
                to={"/create-mandate"}
                style={{ color: "#fff" }}
                className="p-2 "
              >
                <GoPlus /> Create Mandate
              </NavLink>
            )}

            <NavLink
              to={"/dashboard"}
              style={{ color: "#fff" }}
              className="p-2 "
            >
              <FiCreditCard /> Dashboard
            </NavLink>

            {/* <NavLink
              to={"/products"}
              style={{ color: "#fff" }}
              className="p-2 "
            >
              <GoPackage /> Products
            </NavLink> */}

            <NavLink
              to={"/upload-file"}
              style={{ color: "#fff" }}
              className="p-2 "
            >
              <FaFile /> Upload Files
            </NavLink>
            <NavLink
              to={"/add-User"}
              style={{ color: "#fff" }}
              className="p-2 "
            >
              <FaUserPlus /> Add User
            </NavLink>
            <NavLink
              to={"/roles"}
              style={{ color: "#fff" }}
              className="p-2 "
            >
              <IoDocumentAttach /> Roles
            </NavLink>


          </div>
        </Col>
        <Col xs={10} className="p-4">
          <div
            style={{
              width: "100%",
              paddingBottom: 15,
              borderBottom: "1px solid #EAECF0",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ textTransform: "capitalize", fontWeight: 700 }}>
              {location.pathname.slice(1).replace(/-/g, " ")}
            </h5>

            <div className="d-flex align-items-center flex-row">
              <div className="round-profile">
                {userData?.firstName?.charAt(0).toUpperCase()}
                {userData?.lastName?.charAt(0).toUpperCase()}
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    backgroundColor: "transparent",
                    color: "#000",
                    border: "none",
                  }}
                  id="dropdown-basic"
                >
                  {user?.userData?.firstName} {user?.userData?.lastName}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
                  <Dropdown.Item
                    onClick={() => {
                      sessionStorage.removeItem("token");
                      navigate("/");
                    }}
                    style={{ color: "red" }}
                  >
                    <FiLogOut /> Log out
                  </Dropdown.Item>
                  <DropdownDivider />
                  <tr>
                    <td style={{ padding: "7px" }}>{userData?.email}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "7px" }}>{userData?.role?.name}</td>
                  </tr>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Outlet />
        </Col>
      </Row>
      {/* <FoundMandates show={show} toggle={toggle} mandates={mandates} /> */}
    </>

    //   <div id="detail"></div>
  );
};
