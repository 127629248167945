import logo from './logo.svg';
import './App.css';
import { Button } from 'react-bootstrap';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthContext } from './components/helper/AuthContext';
import { Root } from './pages/Root';
import { AddUser } from './pages/AddUser';
import { UploadedFiles } from './pages/UploadedFiles';
import { Roles } from './pages/Roles';
import ActivateUser from './pages/ActivateUser';

function App() {

  const router = createBrowserRouter([
    {
      // path: "/login",
      element: <Root />,
      // errorElement: <ErrorPage />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "add-User",
          element: <AddUser />,
        },
        {
          path: "upload-file",
          element: <UploadedFiles />,
        },
        {
          path: "roles",
          element: <Roles />,
        }
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "activate-User/:id",
      element: <ActivateUser />,
    },
  ]);

  const handleRouteChange = () => {
    console.log(window.location.pathname);
    sessionStorage.setItem("currentRoute", window.location.pathname);
  };


  return (
//     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, minHeight: '100vh'}}>
//     <div class="spinner">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// </div>
    <>
          <AuthContext.Provider
            value={{
              // user,
              // setUser,
              // roles,
              // statuses,
              // banks,
              // frequencies,
              // institutions,
              // isAuthenticated,
              // setIsAuthenticated,
              // loading,
              // setLoading,
              // isFilterActive, 
            }}
          >
            <RouterProvider router={router} onChange={handleRouteChange} />
          </AuthContext.Provider>
        </>
  );
}

export default App;
