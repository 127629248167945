import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { registerNewUser } from "../../services";
import { notify } from "../actions/CommonActions";
import { createUser, getRoles, getSingleUser, updateSingleUser } from "../../services";
import ReactPaginate from "react-paginate";
// import { AuthContext } from "../../helpers/AuthContext";

export const UpdateUserModal = ({
  showUser,
  toggleShowUser,
  role,
  setCurrentPage,
  setIsRolesLoading,
  numInput,
  setRole,
  setTotalPages,
  currentPage,
  userId,
  refetch
}) => {

  const [register, setRegister] = useState({
    firstName: "",
    lastName: "",
    staffId: "",
    roleId: "",
  });
  const [isRegistering, setIsRegistering] = useState(false);
  const [loading, setLoading] = useState(false)
  const [userRole, setUserRole] = useState(null)
//   const { roles, user } = useContext(AuthContext);
  const handleRegisterChange = (e) => {
    const name = e.target.name;
    const value = name === "roleId" ? parseInt(e.target.value) : e.target.value;
    setRegister({ ...register, [name]: value });
  };

  useEffect(()=>{
    const singleRole = async() =>{
      setLoading(true)
      if(showUser){
      await getSingleUser(userId?.id)
      .then((res) => {
        // setShowUser(false);
        const filteredRole = role?.data?.roles?.filter((item)=> item?.id == res?.data?.roleId )
        setUserRole(filteredRole)
        setRegister({
            firstName: res?.data?.firstName,
            lastName: res?.data?.lastName,
            staffId: res?.data?.staffId,
            roleId: filteredRole[0]?.name,
          })
        // setPermissions(res?.data?.permissions)
        })
      }
      setLoading(false)
    }
    singleRole()
  },[showUser])


  const UpdateUserCall = async (values) => {
    setIsRegistering(true);
    await updateSingleUser(userId?.id,values)
      .then((res) => {
        notify("success", "User updated.", res?.message);
        // setShowUser(false);
        setRegister({
          firstName: "",
          lastName: "",
          email: "",
          staffId: "",
          roleId: "",
        })
        setIsRegistering(false);
        toggleShowUser()
        refetch();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsRegistering(false);
      });
  };

  return (
    <Modal
      show={showUser}
      onHide={toggleShowUser}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Update User</Modal.Title>
        </div>
      </Modal.Header>
      {loading? 
      <p>Loading...</p>:
      <Modal.Body>
        <div>
          <Form className="d-flex flex-column gap-3">
            <Row>
              <Form.Group as={Col}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  onChange={handleRegisterChange}
                  value={register?.firstName}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name="lastName" onChange={handleRegisterChange} value={register?.lastName} />
              </Form.Group>
            </Row>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" value={userId?.email} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff ID</Form.Label>
              <Form.Control name="staffId" onChange={handleRegisterChange} value={register?.staffId} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Select name="roleId" value={register?.roleId} onChange={handleRegisterChange}>
                <option></option>
                {role?.data?.roles?.map((role) => {
                  return (
                    <option key={role?.id} value={role?.id}>
                      {role?.name}
                    </option>
                  );
                })}
              </Form.Select>
              {/* <Form.Control name="roleId" onChange={handleRegisterChange} /> */}
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                onChange={handleRegisterChange}
              />
            </Form.Group> */}

            <Button
              onClick={(e) => {
                  e.preventDefault();
                UpdateUserCall(register);
              }}
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isRegistering}
            >
              {isRegistering ? "Submitting..." : "Submit"}
            </Button>
          </Form>

          {/* <div className="pagination-container">
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div> */}
        </div>
      </Modal.Body>
}
      {/* <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
        >
          Search
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
