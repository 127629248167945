import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
// import { registerNewUser } from "../../services";
import { notify } from "../actions/CommonActions";
import { createUser, getRoles, updateSingleUploadedFile } from "../../services";
import ReactPaginate from "react-paginate";
// import { AuthContext } from "../../helpers/AuthContext";

export const UpdatePolicyModal = ({
  showUser,
  setShowUser,
  toggleShowUser,
  refetch,
  selectedPolicy
}) => {
  const [register, setRegister] = useState({
    firstName: "",
    lastName: "",
    email: "",
    staffId: "",
    roleId: "",
  });
  const [isRegistering, setIsRegistering] = useState(false);
  const [updatePolicy, setUpdatePolicy] = useState(null)
//   const { roles, user } = useContext(AuthContext);
const roles= 'admin'
const user = ''
  const navigate = useNavigate();
  const handleRegisterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setRegister({ ...register, [name]: value });
  };

  useEffect(()=>{
    const update = () =>{
      setUpdatePolicy(JSON.stringify(selectedPolicy?.noOfEmployees))
    }
    update()
  },[selectedPolicy])


  const UpdatePolicyCall = () => {
    setIsRegistering(true);
    const sentData ={
      noOfEmployees: Number(updatePolicy)
    }
    // console.log('submit ', sentData);
    updateSingleUploadedFile(selectedPolicy?.id ,sentData)
      .then((res) => {
        console.log('res user created ',res);
        notify("success", "User created.", res?.message);
        // setShowUser(false);
        setIsRegistering(false);
        toggleShowUser()
        refetch();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsRegistering(false);
      });
  };

  return (
    <Modal
      show={showUser}
      onHide={toggleShowUser}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Update Policy</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form className="d-flex flex-column gap-3">
            
            <Form.Group>
              <Form.Label>Number of employees</Form.Label>
              <Form.Control name="noOfEmployees" type="text" value={updatePolicy || ""}
    onChange={(e) => setUpdatePolicy(e.target.value)}/>
            </Form.Group>

            <Button
              onClick={(e) => {
                  e.preventDefault();
                // console.log("reg ",register);
                UpdatePolicyCall();
              }}
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isRegistering}
            >
              {isRegistering ? "Updating policy..." : "Update policy"}
            </Button>
          </Form>

        </div>
      </Modal.Body>
    </Modal>
  );
};
