import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";

export const DeactivateUserModal = ({
  show,
  toggle,
  handleDeactivateUser,
  isChanging,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <div> */}
        <Modal.Title>Deactivate User</Modal.Title>
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to proceed? Proceeding will Deactivate this
          user.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleDeactivateUser();
          }}
          disabled={isChanging}
        >
          {isChanging ? "Deactivating..." : "Deactivate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
