import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { registerNewUser } from "../../services";
import { notify } from "../actions/CommonActions";
import { createUser, getRoles } from "../../services";
import ReactPaginate from "react-paginate";
// import { AuthContext } from "../../helpers/AuthContext";

export const NewUserModal = ({
  showUser,
  setShowUser,
  toggleShowUser,
  refetch,
  role,
  setCurrentPage,
  setIsRolesLoading,
  numInput,
  setRole,
  setTotalPages,
  currentPage,
  totalPages,
  setNumInput
}) => {
  const [register, setRegister] = useState({
    firstName: "",
    lastName: "",
    email: "",
    staffId: "",
    roleId: "",
  });
  const [isRegistering, setIsRegistering] = useState(false);
//   const { roles, user } = useContext(AuthContext);
const roles= 'admin'
const user = ''
  const navigate = useNavigate();
  const handleRegisterChange = (e) => {
    const name = e.target.name;
    const value = name === "roleId" ? parseInt(e.target.value) : e.target.value;
    setRegister({ ...register, [name]: value });
  };

  const handlePageClick = async(data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage)
    setIsRolesLoading(true)
    await getRoles(numInput, selectedPage)
      .then((res)=>{
        // console.log('res ',res);
        setRole(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        console.log('error ',error);
      })
      setIsRolesLoading(false)
  };

  const handleSetPageClick = async(data) => {
    setIsRolesLoading(true)
    await getRoles(numInput, currentPage)
      .then((res)=>{
        // console.log('res ',res);
        setRole(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        console.log('error ',error);
      })
      setIsRolesLoading(false)
  };

  const CreateUserCall = (values) => {
    setIsRegistering(true);
    createUser(values)
      .then((res) => {
        console.log('res user created ',res);
        notify("success", "User created.", res?.message);
        // setShowUser(false);
        setRegister({
          firstName: "",
          lastName: "",
          email: "",
          staffId: "",
          roleId: "",
        })
        setIsRegistering(false);
        toggleShowUser()
        refetch();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsRegistering(false);
      });
  };

  return (
    <Modal
      show={showUser}
      onHide={toggleShowUser}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>New User</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form className="d-flex flex-column gap-3">
            <Row>
              <Form.Group as={Col}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  onChange={handleRegisterChange}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name="lastName" onChange={handleRegisterChange} />
              </Form.Group>
            </Row>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" onChange={handleRegisterChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff ID</Form.Label>
              <Form.Control name="staffId" onChange={handleRegisterChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Select name="roleId" onChange={handleRegisterChange}>
                <option></option>
                {role?.data?.roles?.map((role) => {
                  return (
                    <option key={role?.id} value={role?.id}>
                      {role?.name}
                    </option>
                  );
                })}
              </Form.Select>
              {/* <Form.Control name="roleId" onChange={handleRegisterChange} /> */}
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                onChange={handleRegisterChange}
              />
            </Form.Group> */}

            <Button
              onClick={(e) => {
                  e.preventDefault();
                // console.log("reg ",register);
                CreateUserCall(register);
              }}
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isRegistering}
            >
              {isRegistering ? "Creating user..." : "Create User"}
            </Button>
          </Form>

          {/* <div className="pagination-container">
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div> */}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
        >
          Search
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
