import axios from "axios";
import { notify } from "../components/actions/CommonActions";
// import { notify } from "../components/actions/CommonActions";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = 12000;
// axios.defaults.headers.post["Accept"] = "application/json";

async function refreshToken() {
  const realToken = sessionStorage.getItem('token')
  const refreshToken = sessionStorage.getItem("refreshToken");
  const response = await axios.post("/auth/refresh", {
    headers: {
      "Authorization": `Bearer ${realToken}`,
      "X-Refresh-Token": `Bearer ${refreshToken}`
    }
  });
  const newAccessToken = response?.data?.token;
  const newRefreshToken = response?.data?.refreshToken;
  sessionStorage.setItem("token", newAccessToken);
  sessionStorage.setItem('refreshToken', newRefreshToken)
  return newAccessToken;
}

axios.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("Request error: ", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      console.log(originalRequest);
  
      // Check if the request was unauthorized and has not already been retried
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark request as retried
  
        try {
          const accessToken = await refreshToken(); // Attempt to refresh token
          // sessionStorage.setItem("token");
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`; // Update the access token in the header
  
          // Retry the original request with the new token
          return axios(originalRequest);
        } catch (refreshError) {
          console.log(refreshError);
          // If token refresh fails, handle accordingly (e.g., redirect to login)
          notify("danger", "Session expired", "Please login again.");
          sessionStorage.clear(); // Optionally clear session storage
          window.location.href = "/"; // Redirect to login page
          return Promise.reject(refreshError);
        }
      }
  
      // Return any other errors
      return Promise.reject(error);
    }
  );



export async function loginUser(values) {
  const { data } = await axios.post(`auth/login`, values);
//   console.log('data ',data);
  return data;
}


export async function getRoles(numInput, page) {
    const { data } = await axios.get(`roles?perPage=${numInput}&page=${page}`);
    return data;
  }

  export async function getRolesUser() {
    const { data } = await axios.get(`roles`);
    return data;
  }

  export async function getPermissions() {
    const { data } = await axios.get(`permissions`);
    return data;
  }

  export async function createRoles(values) {
    const { data } = await axios.post(`roles`, values);
    return data;
  }

  export async function deleteRoles(id ,values) {
    const { data } = await axios.delete(`roles/${id}`, values);
    return data;
  }

  export async function getSingleRoles(id) {
    const { data } = await axios.get(`roles/${id}`);
    return data;
  }

  export async function updateSingleRoles(id, values) {
    const { data } = await axios.patch(`roles/${id}`, values);
    return data;
  }

  export async function createUser(values) {
    const { data } = await axios.post(`users`, values);
    return data;
  }

  export async function activateUser(values) {
    const { data } = await axios.post(`users/activate`, values);
    return data;
  }

  export async function uploadPolicy(values) {
    const { data } = await axios.post(`policy/upload`, values, {
      headers: {
        "Content-Type":"multipart/form-data"
      }
    });
    return data;
  }

  export async function uploadMember(values) {
    const { data } = await axios.post(`member/upload`, values, {
      headers: {
        "Content-Type":"multipart/form-data"
      }
    });
    return data;
  }

  // export async function getUsers() {
  //   const { data } = await axios.get(`users`);
  //   return data;
  // }

  export async function getUsers(numInput, page) {
    const { data } = await axios.get(`users?perPage=${numInput}&page=${page}`);
    return data;
  }

  export async function deleteUser(id ,values) {
    const { data } = await axios.delete(`users/${id}`);
    return data;
  }

  export async function getSingleUser(id) {
    const { data } = await axios.get(`users/${id}`);
    return data;
  }

  export async function updateSingleUser(id, values) {
    const { data } = await axios.patch(`users/${id}`, values);
    return data;
  }

  export async function getPolicy(numInput, page) {
    const { data } = await axios.get(`policy?perPage=${numInput}&page=${page}`);
    return data;
  }

  export async function updateSingleUploadedFile(id, values) {
    const { data } = await axios.patch(`policy/${id}`, values);
    return data;
  }

  export async function getDashboard() {
    const { data } = await axios.get(`dashboard`);
    return data;
  }