import React, { useContext, useEffect, useState } from "react";
import { IoPerson } from "react-icons/io5";
import { Col, Dropdown, DropdownDivider, Row } from "react-bootstrap";
import {
  activateUser,
  changeRole,
  deactivateUser,
  forgotPassword,
  getRoles,
  getRolesUser,
  getSingleRoles,
  getUsers,
} from "../services";
import { useQuery } from "react-query";
import { notify } from "../components/actions/CommonActions";
import { ChangeRoleModal } from "../components/modals/ChangeRoleModal";
// import { AuthContext } from "../helpers/AuthContext";
import { ChangePasswordModal } from "../components/modals/DeleteRoleModal";
import { DeactivateUserModal } from "../components/modals/DeactivateUserModal";
import { ActivateUserModal } from "../components/modals/ActivateUserModal";
import { NewUserModal } from "../components/modals/NewUserModal";
import ReactPaginate from "react-paginate";
import { DeleteUserModal } from "../components/modals/DeleteUserModal";
import { UpdateUserModal } from "../components/modals/UpdateUserModal";

import '../App.css'

export const AddUser = () => {
  const [showUser, setShowUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [showChange, setShowChange] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [showActivateUser, setShowActivateUser] = useState(false);
  const [role, setRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [email, setEmail] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [numInput, setNumInput] = useState(10);
  const [userGotten, setuserGotten] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
//   const { user } = useContext(AuthContext);
const [userChosen, setuserChosen] = useState(null)

  const toggleShowUser = () => {
    setShowUser(!showUser);
  };

  const toggleShowPassword = () => {
    setShowChangePassword(!showChangePassword);
  };

  const toggleShowChange = () => {
    setShowChange(!showChange);
  };

  const toggleShowDeactivateUser = () => {
    setShowDeactivateUser(!showDeactivateUser);
  };

  const toggleShowActivateUser = () => {
    setShowActivateUser(!showActivateUser);
  };

const refetch = async () =>{
  setIsLoading(true)
  await getUsers(numInput, 1)
  .then(async (res)=>{
    // console.log('res ',res);
    const usersWithStatus = res?.data?.users ?? [];
  await Promise.all(usersWithStatus.map(updateStatus));
  setUsers(res);
  setuserGotten(usersWithStatus)
    setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
  })
    .catch((error)=>{
      notify("danger", "OOPS", error?.response?.data?.message);
      console.log('error ',error);
    })
    setIsLoading(false)
}

const updateStatus = async (item) => {
  try {
    const response = await getSingleRoles(item?.roleId);
    item.role = response?.data?.name; // assuming the response has a 'status' field
  } catch (error) {
    console.error('Error updating status:', error);
  }
};

useEffect(() => {
  const getRolesApi = async () => {
    setIsLoading(true);
    try {
      const res = await getUsers(numInput, currentPage);
      const usersWithStatus = res?.data?.users ?? [];
      await Promise.all(usersWithStatus.map(updateStatus));
      const getRolesUser = await getRoles(10, 1)
      // console.log('getRolesUser ',getRolesUser);
      setRole(getRolesUser)
      setUsers(res);
      setuserGotten(usersWithStatus)
      setTotalPages(Math.ceil(Number(res?.data?.totalCount) / Number(numInput)));
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  };
  getRolesApi();
}, []);

  const roles = sessionStorage.getItem("roles");
  const parsedRoles = JSON.parse(roles);

  const getRole = (roleId) => {
    // const role = parsedRoles?.data?.find((role) => role.id === roleId);

    // return role?.name;
  };

  const handlePageClick = async(data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage)
    setIsLoading(true)
    await getUsers(numInput, selectedPage)
      .then(async (res)=>{
        // console.log('res ',res);
        const usersWithStatus = res?.data?.users ?? [];
      await Promise.all(usersWithStatus.map(updateStatus));
      setUsers(res);
      setuserGotten(usersWithStatus)
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsLoading(false)
  };

  const handleSetPageClick = async(data) => {
    setIsLoading(true)
    await getUsers(numInput, currentPage)
      .then(async(res)=>{
        // console.log('res ',res);
        const usersWithStatus = res?.data?.users ?? [];
      await Promise.all(usersWithStatus.map(updateStatus));
      setUsers(res);
      setuserGotten(usersWithStatus)
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsLoading(false)
  };



  return (
    <>
      <Row className="mt-3">
        
          <Col>
            <div className="w-100">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <h6>USERS</h6>
                <button
                  onClick={() => {
                    toggleShowUser();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "0.5px solid gray",
                    fontSize: "0.9rem",
                    borderRadius: "5px",
                  }}
                  className="p-1 d-flex align-items-center gap-2"
                >
                  <IoPerson /> +
                </button>
              </div>
              <div class="table-wrapper">
              <table className="fl-table">
                <thead>
                  <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>...</th>
                  </tr>
                </thead>

                <tbody>
                {isLoading ? (
                  <>
                    <td className="gray-td">Loading, please wait...</td>
                  </>
                ) : (
                  userGotten?.map((user) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "0.5px solid #f1f1f1",
                        }}
                      >
                        <td>
                          {user?.firstName} {user?.lastName}
                        </td>
                        <td>{user?.email}</td>
                        <td>{user?.role}</td>
                        <td
                          style={{
                            color: user?.status === "ACTIVE" ? "green" : "red",
                          }}
                        >
                          {user?.status === "ACTIVE" ? "active" : "inactive"}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                backgroundColor: "transparent",
                                color: "#000",
                                border: "none",
                                padding: 0,
                              }}
                              id="dropdown-basic"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
                              <Dropdown.Item
                                onClick={() => {
                                  setUserId(user);
                                  toggleShowChange();
                                }}
                              >
                                update user
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowPassword();
                                  setuserChosen(user);
                                }}
                              >
                                delete user
                              </Dropdown.Item>

                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
                </tbody>
              </table>
              </div>
              <div className="pagination-container">
        {/* Pagination controls */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          // pageClassName={"pagination-page "}
          // previousClassName={"item previous"}
          // nextClassName={"item next "}
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            // const storedPage = sessionStorage.getItem("currentPage");
            // const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
            // setNumInput(sessionStorage.getItem("pageNum"));
            // fetchMandates(initialPage);
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div>
            </div>
          </Col>
        
        {/* <Col></Col> */}
      </Row>
      <NewUserModal
        showUser={showUser}
        setShowUser={setShowUser}
        toggleShowUser={toggleShowUser}
        refetch={refetch}
        role={role}
        setIsRolesLoading={setIsLoading}
        setCurrentPage={setCurrentPage}
        numInput={numInput}
        setRole={setRole}
        setTotalPages={setTotalPages}
        currentPage={currentPage}
        totalPages={totalPages}
        setNumInput={setNumInput}
      />

<UpdateUserModal
        showUser={showChange}
        setShowUser={setShowChange}
        toggleShowUser={toggleShowChange}
        refetch={refetch}
        role={role}
        setIsRolesLoading={setIsLoading}
        setRole={setRole}
        setTotalPages={setTotalPages}
        currentPage={currentPage}
        userId={userId}
      />

<DeleteUserModal
        show={showChangePassword}
        toggle={toggleShowPassword}
        isChanging={isChanging}
        setIsChanging={setIsChanging}
        refetch={refetch}
        user={userChosen}
      />
    </>
  );
};
