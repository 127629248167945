import React, { useContext, useEffect, useState } from "react";
import { IoDocumentAttach, IoPerson } from "react-icons/io5";
import { Col, Dropdown, DropdownDivider, Row } from "react-bootstrap";
import {
  activateUser,
  changeRole,
  deactivateUser,
  forgotPassword,
  getPermissions,
  getRoles,
  getUsers,
} from "../services";
import { useQuery } from "react-query";
import { notify } from "../components/actions/CommonActions";
import { ChangeRoleModal } from "../components/modals/ChangeRoleModal";
// import { AuthContext } from "../helpers/AuthContext";
import { DeactivateUserModal } from "../components/modals/DeactivateUserModal";
import { ActivateUserModal } from "../components/modals/ActivateUserModal";
import { GoDotFill } from "react-icons/go";
import { NewRoleModal } from "../components/modals/NewRoleModal";
import { UpdateRoleModal } from "../components/modals/UpdateRoleModal";
import { DeleteRoleModal } from "../components/modals/DeleteRoleModal";
import ReactPaginate from "react-paginate";
import '../App.css'

export const Roles = () => {
  const [showUser, setShowUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [showChange, setShowChange] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [showActivateUser, setShowActivateUser] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState(null)

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [numInput, setNumInput] = useState(10);
  const [userGotten, setuserGotten] = useState(null);
  const [isRolesLoading, setIsRolesLoading] = useState(false)

  const handlePageClick = async(data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage)
    setIsRolesLoading(true)
    await getRoles(numInput, selectedPage)
      .then((res)=>{
        // console.log('res ',res);
        setuserGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsRolesLoading(false)
  };

  const handleSetPageClick = async(data) => {
    setIsRolesLoading(true)
    await getRoles(numInput, currentPage)
      .then((res)=>{
        // console.log('res ',res);
        setuserGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsRolesLoading(false)
  };

  const toggleShowUser = () => {
    setShowUser(!showUser);
  };

  const toggleShowPassword = () => {
    setShowChangePassword(!showChangePassword);
  };

  const toggleShowChange = () => {
    setShowChange(!showChange);
  };

  const toggleShowDeactivateUser = () => {
    setShowDeactivateUser(!showDeactivateUser);
  };

  const toggleShowActivateUser = () => {
    setShowActivateUser(!showActivateUser);
  };

  //   const refetch= false
  useEffect(()=>{
    sessionStorage.setItem('idUpdate', 0)
  },[])

  // const {
  //   data: rolesData,
  //   status: rolesStatus,
  //   refetch: refetchRoles,
  //   isSuccess: isRolesSuccess,
  //   isLoading: isRolesLoading,
  //   isFetching: isRolesFetching,
  // } = useQuery("all-roles-dash", getRoles(numInput), {
  //   onSuccess: (res) => {
  //     console.log('res ',res);
  //     setuserGotten(res);
  //     setTotalPages(res?.data?.totalCount)
  //   },
  //   onError: (error) => {
  //     notify("danger", "Error", "Something went wrong");
  //     console.log(error);
  //   },
  // });

  const refetchRoles = async () =>{
    setIsRolesLoading(true)
      await getRoles(numInput, 1)
      .then((res)=>{
        // console.log('res ',res);
        setuserGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsRolesLoading(false)
  }

  useEffect(()=>{
    const getRolesApi = async () =>{
      setIsRolesLoading(true)
      await getRoles(numInput, currentPage)
      .then((res)=>{
        // console.log('res ',res);
        setuserGotten(res);
      setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsRolesLoading(false)
    }
    getRolesApi()
  },[])

  const {
    data: permissionsData,
    status: permissionsStatus,
    refetch: refetchPermissions,
    isSuccess: isPermissionsSuccess,
    isLoading: isPermissionsLoading,
    isFetching: isPermissionsFetching,
  } = useQuery("all-permissions", getPermissions, {
    onSuccess: (res) => {
        setRoles(res);
    },
    onError: (error) => {
      notify("danger", "Error", "Failed to fetch permissions");
      console.log(error);
    },
  });

  const fetchMandates = async (page) => {
    // setIsLoading(true);
    // try {
    //   const response = await getAllMandates(page, numInput);
    //   // setMandates(response.data);
    //   setTotalPages(response.totalPages);
    //   setCurrentPage(page);
    //   sessionStorage.setItem("currentPage", page);
    //   const mandatesWithUserNames = await Promise.all(
    //     response.data.map(async (mandate) => ({
    //       ...mandate,
    //       initiatorName: getInitiator(mandate.userId),
    //       approverName: getApprover(mandate.approverId),
    //     }))
    //   );
    //   setMandates(mandatesWithUserNames);
    //   // console.log(mandates);
    // } catch (error) {
    //   setError(error.message);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const getRole = (roleId) => {
    
  };

  const handleChangeRole = async () => {
    
  };

  const handleChangePassword = async () => {
    
  };

  const handleDeactivateUser = async () => {
    
  };

  const handleActivateUser = async () => {
    
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <div className="w-100">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <h6>ROLES</h6>
              <button
                onClick={() => {
                  toggleShowUser();
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "0.5px solid gray",
                  fontSize: "0.9rem",
                  borderRadius: "5px",
                }}
                className="p-1 d-flex align-items-center gap-2"
              >
                <IoDocumentAttach /> +
              </button>
            </div>

            <div class="table-wrapper">
            <table className="fl-table">
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  <th>Name</th>
                  <th>Permissions</th>
                  <th>...</th>
                </tr>
              </thead>

              <tbody>
                {isRolesLoading ? (
                  <>
                    <td className="gray-td">Loading, please wait...</td>
                  </>
                ) : (
                  userGotten?.data?.roles?.map((user, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "0.5px solid #f1f1f1",
                        }}
                      >
                        <td>{user?.name}</td>
                        <td>
                          {/* <ul>
                            {user?.permissions?.map((item, index) => (
                              <li key={index}>
                                <GoDotFill /> {item}
                              </li>
                            ))}
                          </ul> */}
                          {user?.permissions?.length > 0 && user?.permissions?.join(', ') + '.'}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                backgroundColor: "transparent",
                                color: "#000",
                                border: "none",
                                padding: 0,
                              }}
                              id="dropdown-basic"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowChange();
                                  setRoleName(user);
                                }}
                              >
                                Update role
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowPassword();
                                  setRole(user);
                                }}
                              >
                                Delete Role
                              </Dropdown.Item>

                              {user?.status === 2 && (
                                <Dropdown.Item
                                  onClick={() => {
                                    toggleShowActivateUser();
                                    // setUserId(user?.id);
                                  }}
                                >
                                  Activate user
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            </div>
            <div className="pagination-container">
        {/* Pagination controls */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          // pageClassName={"pagination-page "}
          // previousClassName={"item previous"}
          // nextClassName={"item next "}
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            // const storedPage = sessionStorage.getItem("currentPage");
            // const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
            // setNumInput(sessionStorage.getItem("pageNum"));
            // fetchMandates(initialPage);
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div>
          </div>
        </Col>

        {/* <Col></Col> */}
      </Row>
      <NewRoleModal
        showUser={showUser}
        setShowUser={setShowUser}
        toggleShowUser={toggleShowUser}
        refetch={refetchRoles}
        roles={roles}
      />
      <UpdateRoleModal
        showUser={showChange}
        toggleShowUser={toggleShowChange}
        setShowUser={setShowUser}
        refetch={refetchRoles}
        roleName={roleName}
        roles={roles}
        role={role}
      />
      <DeleteRoleModal
        show={showChangePassword}
        toggle={toggleShowPassword}
        isChanging={isChanging}
        setIsChanging={setIsChanging}
        refetch={refetchRoles}
        handleChangePassword={handleChangePassword}
        role={role}
      />

      <DeactivateUserModal
        show={showDeactivateUser}
        toggle={toggleShowDeactivateUser}
        isChanging={isChanging}
        handleDeactivateUser={handleDeactivateUser}
      />

      <ActivateUserModal
        show={showActivateUser}
        toggle={toggleShowActivateUser}
        isChanging={isChanging}
        handleActivateUser={handleActivateUser}
      />
    </>
  );
};
