import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";
import { deleteRoles } from "../../services";
import { notify } from "../actions/CommonActions";

export const DeleteRoleModal = ({
  show,
  toggle,
  handleChangePassword,
  isChanging,
  role,
  refetch,
  setIsChanging
}) => {
  // console.log('new role ',role);

  const DeleteRoleCall = (role) => {
    setIsChanging(true);
    // e.preventDefault();
    deleteRoles(role?.id, role?.name)
      .then((res) => {
        console.log('res ',res);
        notify("success", "Role Deleted.", res?.message);
        // setShowUser(false);
        setIsChanging(false);
        toggle()
        refetch();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsChanging(false);
      });
  };


  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <div> */}
        <Modal.Title>Delete Role</Modal.Title>
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to proceed? Proceeding will delete the role.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            DeleteRoleCall(role);
          }}
          disabled={isChanging}
        >
          {isChanging ? "Loading..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
