import React, { useState } from "react";
import { Button, Col, Form, Row, Alert } from "react-bootstrap";
import { activateUser, loginUser } from "../services";
import { notify } from "../components/actions/CommonActions";
import { useNavigate, useParams } from "react-router-dom";
import LoginImage from '../assets/images/groupLifeHome.png';
import logo from "../assets/images/blueLogo.png";
import axios from "axios";

const ActivateUser = () => {
  const [login, setLogin] = useState({ password: "", confirmPassword: "" });
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
//   console.log('id ', id);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const validatePassword = () => {
    const { password, confirmPassword } = login;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }
    if (password.length < 8 || !specialCharRegex.test(password)) {
      setError("Password must be more than 8 characters and have special characters.");
      return false;
    }
    return true;
  };

  async function loginAcc(e) {
    e.preventDefault(); // Prevent form submission
    setError(""); // Reset error message
    if (!validatePassword()) return;
    setIsLoggingIn(true);
    const sentData = {
        activationCode: id,
        password: login?.password
    }
    try {
      const data = await activateUser(sentData);
      notify("success", "Account Activated Successful", "User is allowed to login.");
      navigate("/");
    //   sessionStorage.setItem("token", data?.data?.token);
    //   sessionStorage.setItem("refreshToken", data?.data?.refreshToken);
    //   sessionStorage.setItem("userdata", JSON.stringify(data?.data));
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
      </Col>
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Activate Account</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Enter your password.
          </p>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" onChange={handleLoginChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                onChange={handleLoginChange}
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
            >
              {isLoggingin ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ActivateUser;
